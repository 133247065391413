<template>
  <div class="lnb">
    <div class="lnbLogo">
      <router-link to="/admin/dashboard">
        <img src="@/assets/images/logo.png" alt="logo" />
      </router-link>
    </div>
    <el-row class="tac mainMenu">
      <el-col :span="12">
        <el-menu class="el-menu-vertical-demo" :default-active="navbarState">
          <el-menu-item index="0" v-if="adminType == 'SUPER'">
            <router-link to="/admin/dashboard">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_home.svg"
                  alt="icon"
                  class="normal"
                />
                대시보드
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item
            index="1"
            v-if="
              adminType == 'PLANNER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/users">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_group.svg"
                  alt="icon"
                  class="normal"
                />
                회원 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item
            index="2"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/placemanage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_place.svg"
                  alt="icon"
                  class="normal"
                />
                웨딩 장소 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item
            index="3"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/collectionmanage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_collection.svg"
                  alt="icon"
                  class="normal"
                />
                웨딩 컬렉션 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-submenu
            index="13"
            v-if="
              adminType == 'ADVISOR' ||
              adminType == 'SUPER' ||
              adminType == 'MANAGER'
            "
          >
            <template slot="title">
              <span
                ><img
                  src="@/assets/images/icon_svg/LNB_tour.svg"
                  alt="icon"
                  class="normal"
                />
                스탬프 투어 관리
              </span>
            </template>
            <el-menu-item index="13-1"
              ><router-link to="/admin/tour/manage">
                <span>투어상담 관리 </span>
              </router-link></el-menu-item
            >
            <el-menu-item index="13-2"
              ><router-link to="/admin/gift/manage">
                <span>기프트 관리</span>
              </router-link></el-menu-item
            >
            <el-menu-item index="13-3"
              ><router-link to="/admin/order/manage">
                <span>기프트 주문 관리</span>
              </router-link></el-menu-item
            >
          </el-submenu>
          <el-menu-item
            index="4"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/storemanage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_store.svg"
                  alt="icon"
                  class="normal"
                />
                스토어 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item
            index="10"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/eventmanage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_event.svg"
                  alt="icon"
                  class="normal"
                />
                이벤트 관리
              </span>
            </router-link>
          </el-menu-item>

          <el-submenu
            index="5"
            v-if="
              adminType == 'ADVISOR' ||
              adminType == 'SUPER' ||
              adminType == 'MANAGER'
            "
          >
            <template slot="title">
              <span
                ><img
                  src="@/assets/images/icon_svg/LNB_planner.svg"
                  alt="icon"
                  class="normal"
                />
                아카이브 관리
              </span>
            </template>
            <el-menu-item index="5-1" v-if="adminType == 'SUPER'"
              ><router-link to="/admin/plannermanage">
                <span>플래너 관리 </span>
              </router-link></el-menu-item
            >
            <el-menu-item index="5-2" v-if="adminType != 'MANAGER'"
              ><router-link to="/admin/schedulemanage">
                <span>스케줄 관리</span>
              </router-link></el-menu-item
            >
            <el-menu-item index="5-3"
              ><router-link to="/admin/archivemanage">
                <span>컨텐츠 관리</span>
              </router-link></el-menu-item
            >
          </el-submenu>
          <el-menu-item
            index="5-1"
            v-if="adminType == 'PLANNER' || adminType == 'ADVISOR'"
          >
            <router-link to="/admin/plannermanage/register">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_register.svg"
                  alt="icon"
                  class="normal"
                />
                플래너 정보 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="5-2" v-if="adminType == 'PLANNER'">
            <router-link to="/admin/schedulemanage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_register.svg"
                  alt="icon"
                  class="normal"
                />
                스케줄 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item
            index="11"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/reviewmanage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_register.svg"
                  alt="icon"
                  class="normal"
                />
                리뷰 관리
              </span>
            </router-link>
          </el-menu-item>

          <el-menu-item
            index="6"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/qnamanage">
              <el-badge :value="qnaCount" :max="99" class="item">
                <span>
                  <img
                    src="@/assets/images/icon_svg/LNB_qna.svg"
                    alt="icon"
                    class="normal"
                  />
                  문의내역 관리
                </span>
              </el-badge>
            </router-link>
          </el-menu-item>

          <el-menu-item
            index="7"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'PLANNER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/consultmanage">
              <el-badge :value="consultCount" :max="99" class="item">
                <span class="bedge">
                  <img
                    src="@/assets/images/icon_svg/LNB_consult.svg"
                    alt="icon"
                    class="normal"
                  />
                  상담내역 관리
                </span>
              </el-badge>
            </router-link>
          </el-menu-item>
          <el-menu-item
            index="12"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/partnershipManage">
              <el-badge
                :value="patnershipCount"
                :max="99"
                class="item"
                :hidden="true"
              >
                <span class="bedge">
                  <img
                    src="@/assets/images/icon_svg/LNB_hand.svg"
                    alt="icon"
                    class="normal"
                  />
                  제휴문의 관리
                </span>
              </el-badge>
            </router-link>
          </el-menu-item>
          <el-menu-item
            index="8"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/bannermanage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_banner.svg"
                  alt="icon"
                  class="normal"
                />
                배너 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item
            index="15"
            v-if="
              adminType == 'MANAGER' ||
              adminType == 'ADVISOR' ||
              adminType == 'SUPER'
            "
          >
            <router-link to="/admin/popupmanage">
              <span>
                <img
                  src="@/assets/images/icon_svg/LNB_banner.svg"
                  alt="icon"
                  class="normal"
                />
                팝업 관리
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="9">
            <router-link to @click.native="logout">
              <span>
                <img
                  src="@/assets/images/icon_svg/icn-logout.svg"
                  alt="icon"
                  class="normal"
                />
                로그아웃
              </span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isActive: false,
      isDropped: false,
      adminType: "",
      plannerId: "",
    };
  },
  computed: {
    ...mapState(["navbarState", "qnaCount", "consultCount", "patnershipCount"]),
  },
  beforeMount() {
    this.plannerId = localStorage.getItem("plannerId");
    this.adminType = localStorage.getItem("adminType");
  },
  methods: {
    logout() {
      this.$confirm("로그아웃 하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        this.$store.dispatch("LOGOUT");
      });
    },
  },
};
</script>
